import * as React from "react"

import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../components/productsPage/shared"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { Row, Col } from "react-flexbox-grid"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { Partners } from "../components/networkPage/"
import { _externalLinkButton, _linkButton } from "../components/shared/buttons"

export const prismicQuery = graphql`
    {
        prismic {
            ...networkFragment
        }
    }
`

interface NetworkPageProps {
    data: {
        prismic: {
            allNetworks: {
                edges: any
            }
        }
    }
}

const NetworkPage: React.FC<NetworkPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allNetworks")
    if (!doc) return null

    return (
        <>
            <HeadComponent
                title="Network | Radicle"
                keywords={prismicGetText(doc.seo_keywords_network)}
                description={prismicGetText(doc.seo_description_network)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Network"
                            title={prismicGetRichHeader(doc.title_network_v2)}
                            lead={prismicGetRichText(doc.lead_network)}
                            image={doc.image_v2.url}
                            heroImage={{
                                isBlured: true,
                                height: "auto"
                            }}
                            leftSideLgWidth={5}
                        />
                    </_Section>

                    <_Section>
                        <Partners title={prismicGetRichHeader(doc.title_partners_v2)} partners={doc.partners} />
                    </_Section>

                    <_Section>
                        <Row center="lg">
                            <Col lg={8} md={10} sm={12}>
                                <_h2 maxWidth="480px" margin="auto">
                                    {prismicGetRichHeader(doc.contact_network_v2)}
                                </_h2>
                                <_Blockquote align={"center"} maxWidth="540px" marginLeft="auto" marginRight="auto">
                                    {prismicGetRichText(doc.contact_description_network)}
                                </_Blockquote>
                                <_linkButton to="/contact">Contact our CEO</_linkButton>
                            </Col>
                        </Row>
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default NetworkPage
