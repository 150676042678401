import * as React from "react"

import { Row, Col } from "react-flexbox-grid"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../../components/shared/headline.styles"
import { _Blockquote } from "../../components/shared/blockquote.styles"
import { PersonSignature } from "../../components/shared/PersonSignature"
import { prismicGetText } from "../../utils/prismicHelper"

import Hexagon4 from "../../images/v2/hexagon-4.svg"
import Hexagon5 from "../../images/v2/hexagon-5.svg"
import styled from "styled-components"

interface PartnersProps {
    title: string | JSX.Element
    partners: PartnerProps[]
}

interface PartnerProps {
    avatar_partner: {
        url: string
    }

    name_partner: any
    position_partner: any
}

const _svgImageLeft = styled.img`
    position: absolute;
    top: -400px;
    right: 95%;
    z-index: -1;
`

const _svgImageRight = styled.img`
    position: absolute;
    top: -180px;
    left: 102%;
    z-index: -1;
`

export const Partners: React.FC<PartnersProps> = ({ title, partners }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <>
            <Row center="xs">
                {!appState.isMobile && <_svgImageLeft src={Hexagon4} />}
                <Col lg={6} md={10} sm={12}>
                    <_h2 marginBottom={1} marginTop={"0"}>
                        {title}
                    </_h2>
                </Col>
                {!appState.isMobile && <_svgImageRight src={Hexagon5} />}
            </Row>

            <Row>
                {partners.map((person: PartnerProps, index: number) => {
                    return (
                        <Col lg={4} md={6} sm={12} key={index}>
                            <PersonSignature
                                image={person.avatar_partner.url}
                                name={prismicGetText(person.name_partner)}
                                title={prismicGetText(person.position_partner)}
                                isMobile={false}
                                className="leftAlign"
                                containerClassName="compresed"
                                darkFont={true}
                            />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}
